.universe {
    position: relative;
    &:before {
        content: "";
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.1409) 0.15%, rgba(255, 255, 255, 0.75) 99.38%);
        opacity: 0.9;
        position: absolute;
        z-index: 0;
        pointer-events: none;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .universe__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 105px 0px;
        z-index: 1;
        position: relative;
        .universe__info {
            width: 60%;
            @media (max-width: 991px) and (min-width: 768px) {
                width: 70%;
            }
        }
        .universe__title {
            font-weight: 700;
            font-size: 36px;
            line-height: 46px;
            margin-bottom: 16px;
        }
        .universe__description {
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 32px;
        }
        .universe__link {
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            color: #E8580D;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            svg {
                margin-left: 12px;
            }
            path {
                stroke: #E8580D;
            }
        }
        .universe__image {
            max-width: 300px;
            margin: 0 auto;
            @media (max-width: 991px) and (min-width: 768px) {
                width: 30%;
                margin-right: 50px;
                max-height: 150px;
            }
        }
        @media (max-width: 768px) {
            flex-direction: column-reverse;
            padding: 50px 0px;
            .universe__info {
                width: 100%;
            }
            .universe__image {
                width: unset;
                margin-bottom: 50px;
                max-height: 150px;
            }
        }
    }
    &_reverse {
        &:before {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.1409) 0.15%, rgba(255, 255, 255, 0.75) 99.38%);
        }
        .universe__content {
            flex-direction: row-reverse;
            @media (max-width: 768px) {
                flex-direction: column-reverse;
            }
        }
    }
    &_center {
        .universe__content {
            justify-content: center;
            .universe__title {
                text-align: center;
                white-space: pre-wrap;
            }
            .universe__description {
                text-align: center;
            }
        }
    }
    // &_dark {
    //     background-image: url("../../../assets/images/universebg.png");
    //     background-repeat: no-repeat;
    //     background-color: rgba(0, 0, 0, 0.99);
    //     background-size: cover;
    //     .universe__content {
    //         .universe__title {
    //             color: #fff;
    //         }
    //         .universe__description {
    //             color: #fff;
    //         }
    //         .universe__link {
    //             color: #fff;
    //             path {
    //                 stroke: #fff;
    //             }
    //         }
    //     }
    // }
}