.yaga-skills {
    margin-top: -3px;
    overflow-y: hidden;
    position: relative;
    padding: 30px 0px;
    .hero-row__col {
        // height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3 {
            font-weight: 700;
            font-size: 44px;
            line-height: 56px;
            color: #EA3231;
            margin-bottom: 24px;
        }
        img {
            height: auto;
            width: 90%;
        }
        ul {
            li {
                padding-left: 20px;
                position: relative;
                font-weight: 400;
                font-size: 22px;
                line-height: 44px;
                color: #1d1d1b;
                &:before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    position: absolute;
                    left: 0;
                    top: 19px;
                    // transform: translateY(-50%);
                    border-radius: 50%;
                    background: #2d2e83;
                }
            }
        }
    }
}

.yaga-info {
    padding-top: 25px;
    .hero-row {
        align-items: center;
        justify-content: space-between;
        padding-top: 25px;
    }
    .hero-row__col {
        width: calc(50% - 30px);
        img {
            width: 100%;
        }
        p {
            font-weight: 300;
            font-size: 24px;
            line-height: 160%;
            color: #1d1d1b;
            margin-bottom: 40px;
            &+img {
                width: 50%;
                margin: 0 auto;
                display: flex;
            }
            &+img.w-80 {
                width: 80%;
            }
        }
        h4 {
            font-weight: 700;
            font-size: 44px;
            line-height: 56px;
            color: #4ABCD6;
            margin-bottom: 27px;
        }
        .heading {
            &_gradient_1 {
                color: #0096D4
            }
            &_gradient_2 {
                color: #4ABCD6
            }
            &_gradient_3 {
                color: #4ABCBA
            }
            &_gradient_4 {
                color: #63BDA2
            }
            &_gradient_5 {
                color: #00795B;
            }
        }
    }
}