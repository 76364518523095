.hero-banner {
    .container-fluid {
        display: flex;
    }
    img {
        height: 100%;
        width: 100%;
        // object-fit: contain;
        @media (max-width: 991px) {
            height: 100%;
        }
        @media (max-width: 768px) {
            height: 100%;
        }
    }
}