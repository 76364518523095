.hero {
    .hero__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 105px 0px;
        .hero__info {
            width: 60%;
            @media (max-width: 991px) and (min-width: 768px) {
                width: 70%;
            }
        }
        .hero__title {
            font-weight: 700;
            font-size: 36px;
            line-height: 46px;
            margin-bottom: 16px;
        }
        .hero__description {
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 32px;
        }
        .hero__link {
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            color: #141414;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            svg {
                margin-left: 12px;
            }
            path {
                stroke: #141414;
            }
        }
        .hero__image {
            max-width: 300px;
            @media (max-width: 991px) and (min-width: 768px) {
                width: 30%;
                margin-right: 50px;
            }
        }
        @media (max-width: 768px) {
            flex-direction: column-reverse;
            padding: 50px 0px;
            .hero__info {
                width: 100%;
            }
            .hero__image {
                width: 80%;
                margin-bottom: 30px;
            }
        }
    }
    &_reverse {
        .hero__content {
            flex-direction: row-reverse;
            @media (max-width: 768px) {
                flex-direction: column-reverse;
            }
        }
    }
    &_center {
        .hero__content {
            justify-content: center;
            .hero__title {
                text-align: center;
                white-space: pre-wrap;
            }
            .hero__description {
                text-align: center;
            }
        }
    }
    &_dark {
        background-image: url("../../../assets/images/herobg.png");
        background-repeat: no-repeat;
        background-color: rgba(0, 0, 0, 0.99);
        background-size: cover;
        .hero__content {
            .hero__title {
                color: #fff;
            }
            .hero__description {
                color: #fff;
            }
            .hero__link {
                color: #fff;
                path {
                    stroke: #fff;
                }
            }
        }
    }
}