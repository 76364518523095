.zarya-skills {
    margin-top: -3px;
    overflow-y: hidden;
    position: relative;
    padding: 150px 0px;
    .hero-row__col {
        // height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3 {
            font-weight: 700;
            font-size: 44px;
            line-height: 56px;
            color: #951b81;
            margin-bottom: 24px;
        }
        img {
            height: auto;
            width: 90%;
        }
        ul {
            li {
                padding-left: 20px;
                position: relative;
                font-weight: 400;
                font-size: 22px;
                line-height: 44px;
                color: #1d1d1b;
                &:before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    position: absolute;
                    left: 0;
                    top: 19px;
                    // transform: translateY(-50%);
                    border-radius: 50%;
                    background: #2d2e83;
                }
            }
        }
    }
    @media (max-width: 1200px) {
        padding: 50px 0;
    }
}

.zarya-info {
    padding-top: 25px;
    &_black {
        background: rgb(2, 2, 2);
        .hero-row {
            align-items: flex-end !important;
        }
    }
    .hero-row {
        align-items: center;
        justify-content: space-between;
        padding-top: 25px;
    }
    .hero-row__col {
        width: calc(50% - 30px);
        img {
            width: 100%;
        }
        p {
            font-weight: 300;
            font-size: 24px;
            line-height: 160%;
            color: #1d1d1b;
            margin-bottom: 40px;
            &+img {
                width: 50%;
                margin: 0 auto;
                display: flex;
            }
            &+img.w-80 {
                width: 80%;
            }
        }
        h4 {
            font-weight: 700;
            font-size: 44px;
            line-height: 56px;
            color: #f18500;
            margin-bottom: 27px;
        }
        .heading {
            &_gradient_1 {
                color: #E71D73
            }
            &_gradient_2 {
                color: #EA5B13
            }
            &_gradient_3 {
                color: #F18500
            }
            &_gradient_4 {
                color: #F8AA00
            }
        }
    }
}

.history {
    padding: 69px 0px;
    h3 {
        font-weight: 700;
        font-size: 44px;
        line-height: 56px;
    }
    p {
        font-weight: 300;
        font-size: 24px;
        line-height: 163%;
        color: #1d1d1b;
        margin-bottom: 0;
    }
    &_zarya {
        h3 {
            color: #d60b52;
            margin-bottom: 28px;
        }
    }
}