.text-row {
    padding: 60px 0px 140px 0px;
    .text-row__title {
        font-weight: 700;
        font-size: 36px;
        line-height: 46px;
        color: #141414;
        text-align: center;
        margin-bottom: 40px;
        @media (max-width: 991px) {
            text-align: left;
        }
    }
    .text-row__items {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .text-row__col {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #141414;
            margin-right: 45px;
            &:last-child {
                margin-right: 0;
            }
        }
        @media (max-width: 991px) {
            flex-direction: column;
            .text-row__col {
                margin-bottom: 40px;
                margin-right: 0;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        @media (max-width: 768px) {
            .text-row__col {}
        }
    }
    @media (max-width: 991px) {
        padding: 100px 0px;
    }
    @media (max-width: 768px) {
        padding: 40px 0px;
    }
}