.konek-skills {
    margin-top: -3px;
    overflow-y: hidden;
    position: relative;
    .hero-row__col {
        height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &_fluid {
            display: block;
            width: 30%;
            // margin-right: 50px;
            img {
                position: absolute;
                left: 0;
                top: 0;
                height: 800px;
                // width: 100%;
            }
        }
        h3 {
            font-weight: 700;
            font-size: 44px;
            line-height: 56px;
            color: #2d2e83;
            margin-bottom: 24px;
        }
        ul {
            li {
                padding-left: 20px;
                position: relative;
                font-weight: 400;
                font-size: 22px;
                line-height: 44px;
                color: #1d1d1b;
                &:before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    position: absolute;
                    left: 0;
                    top: 19px;
                    // transform: translateY(-50%);
                    border-radius: 50%;
                    background: #2d2e83;
                }
            }
        }
    }
}

.history {
    padding: 69px 0px;
    h3 {
        font-weight: 700;
        font-size: 44px;
        line-height: 56px;
    }
    p {
        font-weight: 300;
        font-size: 24px;
        line-height: 163%;
        color: #1d1d1b;
        margin-bottom: 0;
    }
    &_konek {
        h3 {
            color: #0096d4;
            margin-bottom: 28px;
        }
    }
}

.visual {
    .hero-row {
        justify-content: space-between;
    }
    .hero-row__col {
        width: calc(50% - 20px);
        img {
            width: 100%;
        }
        h4 {
            font-weight: 700;
            font-size: 44px;
            line-height: 56px;
            color: #00ace6;
            margin-bottom: 27px;
        }
        p {
            font-weight: 300;
            font-size: 24px;
            line-height: 160%;
            color: #1d1d1b;
            margin-bottom: 40px;
            &+img {
                width: 80%;
                margin: 0 auto;
                display: flex;
            }
        }
    }
    &_konek {
        h4 {
            color: #00ace6 !important;
        }
    }
}

.visual-grid {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "first first second" "third fourth fourth";
    align-items: center;
    img {
        width: 100%;
        &[data-number="1"] {
            grid-area: first;
        }
        &[data-number="2"] {
            grid-area: second;
            width: 130%;
        }
        &[data-number="3"] {
            grid-area: third;
        }
        &[data-number="4"] {
            grid-area: fourth;
        }
    }
}

.konek-info {
    .hero-row {
        align-items: flex-start;
    }
    .hero-row__block {
        width: 100%;
        img {
            width: 100%;
        }
    }
    .hero-row__col {
        width: 50%;
        img {
            width: 100%;
        }
        p {
            font-weight: 300;
            font-size: 24px;
            line-height: 160%;
            color: #1d1d1b;
            margin-bottom: 40px;
            &+img {
                width: 50%;
                margin: 0 auto;
                display: flex;
            }
        }
        h4 {
            font-weight: 700;
            font-size: 44px;
            line-height: 56px;
            color: #00A54F;
            margin-bottom: 27px;
        }
        .heading {
            &_gradient_1 {
                color: #00A585;
            }
            &_gradient_2 {
                color: #00A54F;
            }
        }
    }
}