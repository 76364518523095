@import "../../../assets/css/variables/colors";
@import "../../../assets/css/mixins/flex";
.promo {
    background: #030303;
    padding: 0 0px 0px;
    min-height: 700px;
    @media (min-width: 768px) and (max-width: 1050px) {
        margin-top: 0;
    }
    .slick__list {
        padding: 0 0 !important;
    }
    // @media (min-width: 1450px) {
    //     .cover {
    //         object-fit: cover !important;
    //     }
    // }
    .promo__slide {
        display: flex !important;
        justify-content: center;
        align-items: center;
        height: 700px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        video {
            @media (min-width: 991px) {
                object-fit: cover;
            }
        }
        @media (min-width: 991px) {
            .promo__link {
                position: absolute;
                height: 350px;
                width: 350px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    @media (max-width: 768px) {
        height: 300px;
        min-height: 300px;
        .promo__slide {
            height: 300px
        }
    }
}

.promo__arrow {
    position: absolute;
}