.icons-links {
    .icons-links__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .icons-links__item {
            width: 30%;
            text-align: center;
            margin-bottom: 30px;
            img {
                width: 60%;
            }
        }
        @media (max-width: 768px) {
            .icons-links__item {
                width: 50%;
            }
        }
    }
}