h2.heading {
    margin-bottom: 12px;
    margin-top: 60px;
    text-align: center;
    font-size: 52px;
    line-height: 62px;
    font-weight: 700;
    color: #141414;
    position: relative;
    &:before {
        content: "";
        width: 100%;
        background: #f5f5f5;
        height: 35px;
        position: absolute;
        left: 0%;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }
    span {
        background: #fff;
        z-index: 1;
        padding: 0 60px;
    }
    @media (max-width: 767px) {
        font-size: 26px;
        line-height: 30px;
        span {
            padding: 0 10px;
        }
        &:before {
            height: 30px;
        }
    }
}