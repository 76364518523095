.menu {
    position: fixed;
    background: #000;
    bottom: 0;
    width: 100%;
    z-index: 100;
    .menu__content {
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        .menu__toggler {
            display: flex;
            background: none;
            border: none;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            width: 45px;
            height: 30px;
            position: relative;
            // margin: 50px auto;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            &:focus,
            &:active {
                outline: none;
            }
            span {
                display: block;
                position: absolute;
                height: 3px;
                width: 100%;
                background: #fff;
                border-radius: 9px;
                opacity: 1;
                left: 0;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: 0.25s ease-in-out;
                -moz-transition: 0.25s ease-in-out;
                -o-transition: 0.25s ease-in-out;
                transition: 0.25s ease-in-out;
                &:nth-child(1) {
                    top: 0px;
                }
                &:nth-child(2) {
                    top: 12px;
                }
                &:nth-child(3) {
                    top: 24px;
                }
            }
            &_show {
                // span {
                //     position: absolute;
                //     margin-left: -15px;
                //     &:first-child {
                //         transform: rotate(45deg);
                //     }
                //     &:last-child {
                //         transform: rotate(-45deg); // margin-left: 15px;
                //     }
                //     &:nth-child(2) {
                //         display: none;
                //     }
                //     // @media (max-width: 991px) {
                //     //     background: #000;
                //     // }
                // }
                span:nth-child(1) {
                    top: 12px;
                    -webkit-transform: rotate(135deg);
                    -moz-transform: rotate(135deg);
                    -o-transform: rotate(135deg);
                    transform: rotate(135deg);
                }
                span:nth-child(2) {
                    opacity: 0;
                    left: -60px;
                }
                span:nth-child(3) {
                    top: 12px;
                    -webkit-transform: rotate(-135deg);
                    -moz-transform: rotate(-135deg);
                    -o-transform: rotate(-135deg);
                    transform: rotate(-135deg);
                }
            }
        }
        // @media (max-width: 991px) {
        //     position: relative;
        // }
    }
    // @media (max-width: 991px) {
    //     width: 32px;
    //     height: 32px;
    //     top: 20px;
    //     background: transparent;
    //     right: 20px;
    //     .menu__content {
    //         height: 32px;
    //     }
    // }
    .menu__list {
        position: fixed;
        width: 100%;
        left: 0;
        height: calc(100% - 62px) !important;
        background: #fff;
        top: 0;
        height: 0;
        z-index: 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateY(150%);
        opacity: 0;
        transition: 0.2s ease-in;
        .menu__link {
            color: #ff671c;
            font-weight: 700;
            font-size: 42px;
            margin-bottom: 15px;
            line-height: 48px;
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                text-decoration: underline;
            }
            &_disabled {
                color: #999;
                pointer-events: none;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &_show {
            transform: translateY(0);
            opacity: 1;
            transition: 0.2s ease-in;
        }
        // @media (max-width: 991px) {
        //     height: 100% !important;
        // }
    }
}