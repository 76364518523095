.info {
    .info__list {
        .info__item {
            padding: 92px 0 100px 0px;
            border-bottom: 1px solid #dfdfdf;
            position: relative;
            transition: 0.2s;
            .info__item-bg {
                top: 60%;
                left: 60%;
                // transform: translate(-50%, -50%);
                width: 0%;
                height: 0%;
                position: absolute;
                pointer-events: none;
                background-size: cover; // display: none;
                background-repeat: no-repeat;
                background-position-y: 50%;
                background-color: rgba(0, 0, 0, 0.98);
                // border-radius: 50%;
                opacity: 0;
                transition: 0.2s;
                z-index: -1;
                &:before {
                    content: "";
                    position: absolute;
                    z-index: 0;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                }
            }
            @media (max-width: 992px) {
                padding: 70px 0px 50px 0px;
                border-bottom: none;
                .info__item-bg {
                    background-size: cover;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    opacity: 1;
                    transform: none;
                    border-radius: 0;
                    transition: 0.2s;
                }
            }
            &:hover {
                transition: 0.2s;
                .info__item-bg {
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    opacity: 1;
                    transform: none;
                    border-radius: 0;
                    transition: 0.2s;
                }
                .info__item-title {
                    color: #fff;
                    z-index: 1;
                }
                .info__item-links {
                    z-index: 1;
                    .info__item-link {
                        border-color: #fff;
                        color: #fff;
                        &:hover {
                            border-color: #ff671c;
                        }
                    }
                }
            }
            .info__item-title {
                font-weight: 700;
                font-size: 52px;
                line-height: 62px;
                color: #141414;
                margin-bottom: 50px;
                z-index: 1;
                @media (max-width: 991px) {
                    font-size: 42px;
                    color: #fff;
                }
            }
            .info__item-links {
                z-index: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                .info__item-link {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 22px;
                    color: #000;
                    border: 1px solid #000000;
                    border-radius: 6px;
                    padding: 11px 20px;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                    @media (max-width: 991px) {
                        font-size: 16px;
                        line-height: 22px;
                        margin-bottom: 20px;
                        color: #fff;
                        border-color: #fff;
                    }
                }
                @media (max-width: 991px) {
                    flex-wrap: wrap;
                }
            }
            &:last-child {
                border-bottom: 0;
            }
            @media (max-width: 768px) {
                padding: 30px 0px 30px 0px;
                height: 450px;
            }
        }
    }
}