.zarya {
    background-image: url("../../../assets/images/footerbg.png"), url("../../../assets/images/zaryamainbg.png");
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.85);
    color: #242423;
}

.zarya__inner {
    background-image: url("../../../assets/images/zaryaLine.svg");
    background-repeat: no-repeat;
    background-position-y: 100%;
    background-size: cover;
}

.zarya__title {
    font-weight: 700;
    font-size: 80px;
    line-height: 92px;
}

.zarya__subtitle {
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
}

.zarya__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    align-self: center;
    height: 740px;
    position: relative;
    .zarya__photo {
        width: 60%;
        margin-right: -100px;
    }
    .zarya__logo {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
    }
    @media (max-width: 768px) {
        height: 400px;
        .zarya__photo {
            display: none;
        }
        .zarya__title,
        .zarya__subtitle {
            text-align: center;
        }
        .zarya__title {
            font-size: 24px;
            line-height: 28px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .zarya__subtitle {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.zarya-info {
    .zarya-info__inner {
        // background-image: url("../../../assets/images/zaryaLine2.svg");
        // background-repeat: no-repeat;
        // background-position-y: 0%;
        // background-size: cover;
        // background-position-x: 50%;
        padding: 100px 0;
        @media (max-width: 768px) {
            padding-top: 0;
            margin-top: -30px;
        }
    }
    .zarya-info__title {
        font-size: 28px;
        line-height: 38px;
        font-weight: 500;
        color: #242423;
    }
    .zarya-info__subtitle {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #242423;
    }
    .zarya-info__block {
        // width: 30%;
        img {
            width: 100%;
            margin-bottom: 50px;
        }
    }
    @media (max-width: 850px) {
        .zarya-info__title,
        .zarya-info__subtitle {
            text-align: center;
        }
    }
    .zarya-info__content {
        display: flex;
        justify-content: space-between;
    }
    .zarya-info__photo {
        max-width: 400px;
        width: 100%;
        @media (max-width: 850px) {
            display: none;
        }
    }
    @media (max-width: 768px) {
        .zarya-info__title {
            font-size: 22px;
            line-height: 28px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .zarya-info__subtitle {
            font-size: 18px;
            line-height: 24px;
        }
        .zarya-info__block img {
            max-width: unset;
            width: 200%;
            transform: translateX(-25%);
        }
    }
}

.zarya-hightech {
    .zarya-hightech__inner {
        background-image: url("../../../assets/images/zaryaLine2.svg");
        background-repeat: no-repeat;
        background-position-y: 0%;
        background-size: cover;
        background-position-x: 50%;
        padding-bottom: 100px;
        @media (max-width: 768px) {
            background-position-x: 0;
            padding-top: 0;
        }
    }
    .zarya-hightech__content {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        // width: 60%;
        margin: 0 auto;
        mix-blend-mode: difference;
        @media (max-width: 991px) {
            flex-direction: column;
            h5,
            h4 {
                text-align: center !important;
                width: 100% !important;
                display: block !important;
            }
        }
        img {
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
        }
        h4 {
            font-weight: 500;
            font-size: 28px;
            line-height: 38px;
            color: #fff;
            text-align: left;
            display: flex;
            align-self: flex-start;
            width: 50%;
            mix-blend-mode: multiply;
        }
        h5 {
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            color: #fff;
            text-align: right;
            width: 50%;
            mix-blend-mode: multiply;
        }
        @media (max-width: 768px) {
            h4 {
                font-size: 22px;
                line-height: 28px;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            h5 {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}

.zarya-end {
    background: #000;
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 991px) {
            flex-direction: column-reverse;
            .zarya-end__content {
                h4,
                h5 {
                    text-align: center;
                }
            }
            img {
                margin-bottom: 50px;
            }
        }
    }
    img {
        width: 100%;
        max-width: 400px;
        border-radius: 50%;
        margin: 0 auto;
    }
    .zarya-end__inner {
        background-image: url("../../../assets/images/zaryaLine3.svg");
        background-repeat: no-repeat;
        background-position-y: 0%;
        background-size: cover;
        background-position-x: 50%;
        padding: 100px 0px;
    }
    .zarya-end__content {
        display: flex;
        flex-direction: column;
        // align-items: center;
        width: 50%;
        margin: 0 auto;
        h4 {
            font-weight: 500;
            font-size: 28px;
            line-height: 38px;
            color: #242423;
            text-align: left;
            display: flex;
            align-self: flex-start;
        }
        h5 {
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            color: #242423;
            text-align: left;
        }
        @media (max-width: 768px) {
            width: 100%;
            h4 {
                font-size: 22px;
                line-height: 28px;
                margin-top: 10px;
                margin-bottom: 10px;
                text-align: center;
                align-self: center;
            }
            h5 {
                font-size: 18px;
                line-height: 24px;
                text-align: center;
            }
        }
    }
    @media (max-width: 768px) {
        .zarya-end__photo {
            width: 80%;
        }
    }
}

.hero-row {
    display: flex;
    // justify-content: space-be;
    align-items: center;
}

@media (max-width: 991px) {
    .visual {
        padding-top: 50px;
    }
}

@media (max-width: 1600px) {
    .hero-row__col_fluid {
        width: 70% !important;
    }
}

@media (max-width: 1800px) and (min-width: 1601px) {
    .hero-row__col_fluid {
        width: 40% !important;
    }
}

@media (max-width: 1300px) {
    .hero-row__col_fluid {
        width: 90% !important;
    }
}

@media (max-width: 1200px) {
    .hero-row__col_fluid {
        width: 100% !important;
        img {
            position: static !important;
        }
    }
    section[class*="-skills"] {
        .hero-row {
            flex-direction: column-reverse;
            .hero-row__col_fluid,
            .hero-row__col {
                height: auto !important;
                padding: 30px 0px;
            }
            img {
                width: 100%;
                height: auto !important;
                object-fit: cover;
                max-height: 600px;
            }
        }
    }
}

@media (max-width: 768px) {
    section[class*="-skills"] .hero-row {
        align-items: flex-start;
        .hero-row__col img {
            width: calc(100% + 40px);
            margin-left: -20px;
        }
        .hero-row__col:first-child {
            padding: 0;
            margin: 0 auto;
        }
    }
    section[class*="-skills"] .hero-row__col h3 {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        &+ul {
            li {
                font-size: 12px;
                line-height: 25px;
                &:before {
                    top: 9px;
                }
            }
        }
    }
    section.hero-separator {
        height: 44px;
        margin-top: -3px;
    }
    section.history {
        padding: 33px 0;
        h3 {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 12px;
        }
        p {
            font-size: 14px;
            line-height: 21px;
        }
    }
    section.visual {
        .hero-row {
            flex-wrap: wrap;
        }
        .hero-row__col {
            width: 100%;
            h4 {
                font-size: 22px;
                line-height: 28px;
                margin-bottom: 10px;
            }
            p {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
    section[class*="-info"] {
        .hero-row {
            flex-wrap: wrap;
            .hero-row__col {
                width: 100%;
                h4 {
                    padding: 10px 0px;
                    font-size: 22px;
                    line-height: 28px;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }
    }
}