.leshiy-skills {
    margin-top: 0;
    overflow-y: hidden;
    position: relative;
    .hero-row__col {
        height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &_fluid {
            display: block;
            width: 30%;
            // margin-right: 50px;
            img {
                position: absolute;
                left: 0;
                top: 0;
                height: 800px;
                // width: 100%;
            }
        }
        h3 {
            font-weight: 700;
            font-size: 44px;
            line-height: 56px;
            color: #243c0d;
            margin-bottom: 24px;
        }
        ul {
            li {
                padding-left: 20px;
                position: relative;
                font-weight: 400;
                font-size: 22px;
                line-height: 44px;
                color: #1d1d1b;
                &:before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    position: absolute;
                    left: 0;
                    top: 19px;
                    // transform: translateY(-50%);
                    border-radius: 50%;
                    background: #2d2e83;
                }
            }
        }
    }
}

.history {
    padding: 69px 0px;
    h3 {
        font-weight: 700;
        font-size: 44px;
        line-height: 56px;
    }
    p {
        font-weight: 300;
        font-size: 24px;
        line-height: 163%;
        color: #1d1d1b;
        margin-bottom: 0;
    }
    &_leshiy {
        h3 {
            color: #6b8958;
            margin-bottom: 28px;
        }
    }
}

.leshiy-info {
    padding-top: 25px;
    .hero-row {
        align-items: center;
        justify-content: space-between;
        padding-top: 25px;
    }
    .hero-row__block {
        width: 100%;
        img {
            width: 100%;
        }
    }
    .hero-row__col {
        width: calc(50% - 30px);
        img {
            width: 100%;
        }
        p {
            font-weight: 300;
            font-size: 24px;
            line-height: 160%;
            color: #1d1d1b;
            margin-bottom: 40px;
            &+img {
                width: 50%;
                margin: 0 auto;
                display: flex;
            }
            &+img.w-80 {
                width: 80%;
            }
        }
        h4 {
            font-weight: 700;
            font-size: 44px;
            line-height: 56px;
            color: #b2ac7f;
            margin-bottom: 27px;
        }
        .heading {
            &_gradient_1 {
                color: #B2AC7F
            }
            &_gradient_2 {
                color: #D7B46A;
            }
        }
    }
}